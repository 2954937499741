<template>
  <div class="container" v-if="checkout.items">
    <div class="cart-title">
      <div class="title">Корзина</div>
    </div>
    <div class="cart-items">
      <div class="cart-items__title title">{{ quantityProduct() }}</div>
      <CartItem v-for="item in checkout.items" :key="item.id" :item="item" />
    </div>
    <div class="cart-buy">
      <div class="container">
        <div class="cart-buy__info">Для оформления заказа нужно войти в аккаунт</div>
        <div class="cart-buy__wrapper">
          <div class="cart-buy__sum">
            <div class="cart-buy__sum-span">Итого:</div>
            <div class="cart-buy__sum-price">{{ checkout.amountOrder }} ₽</div>
          </div>
          <div class="cart-buy__btn">
            <button class="btn" @click="$router.push('/login')">
              Авторизация
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container" v-else>
    <div class="free-content">
      <div class="free-content__icon">
        <img src="/img/icons/basket.svg" />
      </div>
      <div class="free-content__title title">Корзина ждет товаров</div>
      <div class="free-content__btn">
        <button class="btn" @click.prevent="$router.push('/catalog')">
          Перейти в каталог
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CartItem from "@/components/cards/cart-item";
import store from "@/store/index.js";

export default {
  beforeRouteEnter(to, from, next) {
    if (!store.getters.isAuthenticated) {
      store.dispatch("CHECKOUT_GUEST_FETCH_DATA").then(() => {
        next();
      });
    } else {
      next("/checkout");
    }
  },

  components: {
    CartItem,
  },

  computed: {
    checkout() {
      return this.$store.state.checkout.checkout;
    },
  },

  methods: {
    quantityProduct() {
      const cases = [2, 0, 1, 1, 1, 2];
      const words = ["товар", "товара", "товаров"];
      const number = this.checkout.items.length;

      return (
        number +
        " " +
        words[
          number % 100 > 4 && number % 100 < 20
            ? 2
            : cases[number % 10 < 5 ? number % 10 : 5]
        ]
      );
    },
  },
};
</script>
