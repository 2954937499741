<template>
  <swiper
    class="swiper-wrapper"
    ref="mySwiper"
    :spaceBetween="28"
    :slidesPerView="'auto'"
  >
    <swiper-slide class="swiper-slide">
      <div class="cart-item">
        <div class="cart-item__image">
          <LazyPicture
            src-placeholder="https://palladi.ru/images/no_img_src.png"
            :src="item.picture || 'https://palladi.ru' + item.PREVIEW_PICTURE"
            :alt="item.name || item.NAME"
            class="blur-up"
          />
        </div>
        <div class="cart-item__content">
          <div class="cart-item__title" :class="{ 'cart-item__title-unavailable': isUnavailable }">
            {{ item.name || item.NAME }}
          </div>
          <div class="cart-item__unavailable" v-if="isUnavailable">
            <img class="icon" src="/img/warning.svg" alt=""/>
            Товар не доступен к покупке.
          </div>
          <div class="cart-item__info">
            <div class="cart-item__counter">
<!--              gifts come with capital ID-->
              <div v-if="item.ID && showGift" class="counter">
                <button
                  @click="addItemProductToCart(), $emit('removeEl')"
                  :disabled="this.item.stokeQuantity - this.productCountInBasket <= 0"
                  class="btn">
                  Выбрать
                </button>
              </div>
              <div class="counter" v-else>
                <button
                  @click="removeItemProductToCart()"
                  class="counter__btn">
                  -
                </button>
                <div class="counter__value">{{ productCountInBasket }} шт</div>
                <button
                  @click="addItemProductToCart()"
                  :disabled="this.item.stokeQuantity - this.productCountInBasket <= 0"
                  class="counter__btn">
                  +
                </button>
              </div>
              <div :style="[styleObject, {transition: 0.3 + 's'}]" v-if="infoText.length > 0">{{ infoText }}</div>
            </div>
            <div v-if="item.price" :class="{ 'cart-item__price--old': item.isDiscount }" class="cart-item__price">
              <div class="cart-item__price-num">
                {{ item.priceDiscount }} ₽<span>/ 1 шт</span>
              </div>
              <div class="cart-item__price-old" v-if="item.isDiscount">
                {{ item.price }} ₽
              </div>
            </div>
          </div>
        </div>
      </div>
    </swiper-slide>
    <swiper-slide class="swiper-slide">
      <button @click.prevent="removeProductToCart()" class="btn cart-item__btn-delete">
        <img src="/img/icons/delete.svg"  alt=""/>
        <span class="cart-item__text">Удалить</span>
      </button>
    </swiper-slide>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
    unavailable: {
      default: [],
      required: false,
    }
  },

  data() {
    return {
      infoText: '',
      styleObject: {},
      showGift: true,
    };
  },

  computed: {
    productCountInBasket() {
      if (this.$store.getters.isAuthenticated) {
        let products = Object.values(this.$store.state.basket.items);
        let product = products.find(item => item.id === this.item.id);

        if (product) return product.quantity;
      } else {
        return this.getQuantity(this.$store.state.basketGuest);
      }

      return 0;
    },

    isUnavailable() {
      if (Array.isArray(this.unavailable)) {
        return this.unavailable.includes(this.item.name)
      } else return false
    }
  },

  methods: {
    removeProductToCart() {
      const data = { PRODUCT_ID: this.item.id, DELETE_PRODUCT: true };
      this.$store
        .dispatch("CHECKOUT_REMOVE_PRODUCT_TO_CART", data)
        .then(() => {
          this.reloadBasket();
        });
    },

    addItemProductToCart() {
      const data = { PRODUCT_ID: this.item.id || this.item.ID, QUANTITY: 1 };
      this.infoText = '';

      this.$store
        .dispatch("CHECKOUT_SEND_PRODUCT_TO_CART", data)
        .then((response) => {
          this.responseTrue(response);
          this.reloadBasket();
        })
        .catch((error) => {
          this.responseError(error);
        });

      setTimeout(() => this.styleObject = {opacity: 0}, 5000);
      setTimeout(() => this.infoText = '', 5300);
    },

    removeItemProductToCart() {
      const data = { PRODUCT_ID: this.item.id || this.item.ID, QUANTITY: 1};
      this.infoText = '';

      this.$store
        .dispatch("CHECKOUT_REMOVE_PRODUCT_TO_CART", data)
        .then((response) => {
          this.responseTrue(response);
          this.reloadBasket();
        })
        .catch((error) => {
          this.responseError(error);
        });

      setTimeout(() => this.styleObject = {opacity: 0}, 5000);
      setTimeout(() => this.infoText = '', 5300);
    },

    responseTrue(response) {
      this.styleObject = {color: 'green'};
      this.infoText = response.data.response.message;
    },

    responseError(error) {
      this.styleObject = {color: 'red'};
      this.infoText = error.response.data.response.message;
    },

    getQuantity(stateBasket) {
      let products = Object.values(stateBasket);
      let product = products.find(item => item.productId === this.item.id);

      if (product) return product.quantity;
    },

    reloadBasket() {
      if (this.$store.getters.isAuthenticated) {
        this.$store.dispatch("CHECKOUT_FETCH_DATA");
      } else {
        this.$store.dispatch("CHECKOUT_GUEST_FETCH_DATA");
      }
    }
  },
};
</script>
